import './Header.css';
import logo from '../images/logo.png';
import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
    const images = [
        require('../images/g1.jpeg'),
        require('../images/g2.jpeg'),
        require('../images/g3.jpeg'),
        require('../images/g4.jpeg')
    ];

    const [currentImage, setCurrentImage] = useState(0);
    const [nextImage, setNextImage] = useState(1);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [headerBodyVisible, setHeaderBodyVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsTransitioning(true);
            setNextImage((currentImage + 1) % images.length);
            
            setTimeout(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % images.length);
                setIsTransitioning(false);
            }, 1500);
        }, 5000);

        return () => clearInterval(interval);
    }, [currentImage, images.length]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (dropdownOpen) setDropdownOpen(false);
        document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
        setHeaderBodyVisible(!isMenuOpen);
    };

    const handleDropdown = (e) => {
        if (window.innerWidth <= 968) {
            e.preventDefault();
            setDropdownOpen(!dropdownOpen);
        }
        if (window.innerWidth > 968) {
            setHeaderBodyVisible(!dropdownOpen);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.dropdown')) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (!dropdownOpen) {
            setHeaderBodyVisible(true);
        }
    }, [dropdownOpen]);

    return (
        <div>
            <div 
                className='header' 
                style={{ 
                    backgroundImage: `url(${images[currentImage]})`,
                    ...(isTransitioning && {
                        '::before': {
                            backgroundImage: `url(${images[nextImage]})`,
                            opacity: 1
                        }
                    })
                }}
            >
                <div className='logo'>
                    <img src={logo} height="50px" width="200px" alt="logo" />
                    <br />
                    <p id="slogan">
                    <i>
                        <span className='first'>Redefining IT </span>
                        <span className='last'>Possibilities</span>
                    </i>
                    </p>
                </div>
                <div className={`links ${isMenuOpen ? 'active' : ''}`}>
                    <a href="/" className='toplink'><b>HOME</b></a>
                    <div className="dropdown">
                        <button 
                            className="dropbtn" 
                            onClick={handleDropdown}
                            type="button"
                        >
                            <b id="service">SERVICES</b>
                        </button>
                        <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
                            <a href="/software">Software Development</a>
                            <a href="/equipment">Equipment Supply</a>
                            <a href="/networking">Networking</a>
                            <a href="/support">IT Support and Maintenance</a>
                            <a href="/consultant">IT Consultancy</a>
                            <a href="/cyber">Cyber Security</a>
                        </div>
                    </div>
                    <a href="/aboutUs" className='toplink'><b>ABOUT US</b></a>
                    <a href="/contactUs" className='toplink'><b>CONTACT US</b></a>
                </div>
                
                <div className="menu-icon" onClick={toggleMenu}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </div>
            </div>
            {headerBodyVisible && (
                <div className='headerbody'>
                    <h1>We're Techmento</h1>
                    <h2>
                        Transforming ideas into <span className="highlight">digital excellence</span>, 
                        delivering <span className="highlight">innovative solutions</span> that 
                        drive your business into the <span className="highlight">future</span>.
                    </h2>
                </div>
            )}
        </div>
    );
}

export default Header;
