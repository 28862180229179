import React, { useEffect, useRef, useState } from 'react';
import './ContactUs.css';
import Header from './Header';
import Footer from './Footer';
import { FaPhone, FaEnvelope, FaGlobe, FaMapMarkerAlt, FaClock } from 'react-icons/fa';

function ContactUs() {
    const containerRef = useRef(null);
    const [formStatus, setFormStatus] = useState({
        submitting: false,
        submitted: false,
        error: null
    });
    
    // Mouse move effect for premium glass effect
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!containerRef.current) return;
            
            const cards = containerRef.current.querySelectorAll('.contact-card');
            
            cards.forEach(card => {
                const rect = card.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                
                card.style.setProperty('--mouse-x', `${x}px`);
                card.style.setProperty('--mouse-y', `${y}px`);
            });
        };
        
        const container = containerRef.current;
        if (container) {
            container.addEventListener('mousemove', handleMouseMove);
        }
        
        return () => {
            if (container) {
                container.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormStatus({ submitting: true, submitted: false, error: null });
        
        // Get form data
        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            service: e.target.service.value,
            message: e.target.message.value
        };
        
        try {
            // Send to backend
            const response = await fetch('/api/contact/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || 'Something went wrong');
            }
            
            // Show success status
            setFormStatus({ 
                submitting: false, 
                submitted: true, 
                error: null 
            });
            
            // Reset form
            e.target.reset();
            
            // Reset success message after 5 seconds
            setTimeout(() => {
                setFormStatus(prev => ({
                    ...prev,
                    submitted: false
                }));
            }, 5000);
            
        } catch (error) {
            console.error('Contact form error:', error);
            setFormStatus({ 
                submitting: false, 
                submitted: false, 
                error: error.message 
            });
            
            // Clear error after 5 seconds
            setTimeout(() => {
                setFormStatus(prev => ({
                    ...prev,
                    error: null
                }));
            }, 5000);
        }
    };

    return (
        <div>
            <Header />
            <div className="contact-us-container" ref={containerRef}>
                {/* Premium Background Effects */}
                <div className="contact-background">
                    <div className="contact-gradient-orb"></div>
                    <div className="contact-pattern-layer"></div>
                    <div className="contact-glow-lines"></div>
                </div>
                
                <div className="contact-content-wrapper">
                    <div className="contact-header">
                        <h1>Get In Touch</h1>
                        <p>We're here to help with any questions about our services. Reach out to us using the information below or fill out the form.</p>
                    </div>
                    
                    <div className="contact-container">
                        <div className="contact-card contact-info">
                            <h2>Contact Information</h2>
                            <div className="contact-item">
                                <FaPhone className="contact-icon" />
                                <p>+255 775 666 800</p>
                            </div>
                            <div className="contact-item">
                                <FaEnvelope className="contact-icon" />
                                <p>info@techmento.co.tz</p>
                            </div>
                            <div className="contact-item">
                                <FaGlobe className="contact-icon" />
                                <p><a href="https://www.techmento.co.tz" target="_blank" rel="noopener noreferrer">www.techmento.co.tz</a></p>
                            </div>
                            <div className="contact-item">
                                <FaMapMarkerAlt className="contact-icon" />
                                <p>Kijangwani, Zanzibar</p>
                            </div>
                            <div className="contact-item">
                                <FaClock className="contact-icon" />
                                <p>Monday to Friday: 09:00 AM - 04:00 PM<br/>Saturday: 09:00 AM - 01:00 PM</p>
                            </div>
                        </div>

                        <div className="contact-card inquiry-form">
                            <h2>Inquiry Form</h2>
                            
                            {formStatus.submitted && (
                                <div className="form-success-message">
                                    <div className="success-icon">✓</div>
                                    <p>Your message has been sent successfully!</p>
                                    <p>We'll get back to you as soon as possible.</p>
                                </div>
                            )}
                            
                            {formStatus.error && (
                                <div className="form-error-message">
                                    <div className="error-icon">!</div>
                                    <p>Failed to send your message: {formStatus.error}</p>
                                    <p>Please try again or contact us directly.</p>
                                </div>
                            )}
                            
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Full Name</label>
                                    <input 
                                        type="text" 
                                        id="name" 
                                        name="name" 
                                        placeholder="Enter your full name" 
                                        required 
                                        disabled={formStatus.submitting}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="email">Email Address</label>
                                    <input 
                                        type="email" 
                                        id="email" 
                                        name="email" 
                                        placeholder="Enter your email address" 
                                        required 
                                        disabled={formStatus.submitting}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input 
                                        type="tel" 
                                        id="phone" 
                                        name="phone" 
                                        placeholder="Enter your phone number" 
                                        required 
                                        disabled={formStatus.submitting}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="service">Service Needed</label>
                                    <select 
                                        id="service" 
                                        name="service" 
                                        required 
                                        disabled={formStatus.submitting}
                                    >
                                        <option value="">Select a Service</option>
                                        <option value="Software Development">Software Development</option>
                                        <option value="Equipment Supply">Equipment Supply</option>
                                        <option value="Networking">Networking</option>
                                        <option value="IT Support and Maintenance">IT Support and Maintenance</option>
                                        <option value="IT Consultancy">IT Consultancy</option>
                                        <option value="Cyber Security">Cyber Security</option>
                                    </select>
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea 
                                        id="message" 
                                        name="message" 
                                        placeholder="Describe your project or inquiry..." 
                                        rows="4" 
                                        required 
                                        disabled={formStatus.submitting}
                                    ></textarea>
                                </div>
                                
                                <button 
                                    type="submit" 
                                    disabled={formStatus.submitting}
                                    className={formStatus.submitting ? 'submitting' : ''}
                                >
                                    {formStatus.submitting ? 'Sending...' : 'Send Message'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;