import React from 'react';
import { Network, Link, Shield, Check, Wifi } from 'lucide-react';
import './Networking.css';
import Footer from '../Footer';
import Header from '../Header';

const LuxuryNetworking = () => {
  // Your color codes
  const primaryColor = "rgba(56, 189, 248, 0.7)";
  const secondaryColor = "rgba(2, 132, 199, 0.7)";
  
  return (
    <div className="luxury-networking-container">
      <Header />
      {/* Header Section with Gradient Background */}
      <div className="header-section">
        <div 
          className="header-gradient"
          style={{ 
            backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` 
          }}
        />
        
        <div className="header-content">
          <h1 className="header-title">
            <span className="font-normal">Advanced</span> Networking Solutions
          </h1>
          <p className="header-description">
            Delivering sophisticated, reliable, and scalable networking infrastructure that ensures 
            seamless connectivity and optimized communication across your entire organization.
          </p>
        </div>
      </div>
      
      {/* Networking Solutions Section */}
      <div className="networking-section">
        <div className="networking-grid">
          {/* Network Design Card */}
          <div className="networking-card">
            <div className="networking-card-bar" style={{ backgroundColor: primaryColor }}></div>
            <div className="networking-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Network size={30} style={{ color: primaryColor }} />
              </div>
              <h2 className="networking-title">Strategic Network Design</h2>
              <p className="networking-description">
                Expertly crafted network architectures designed to optimize performance, 
                ensure scalability, and strengthen security across your entire business ecosystem.
              </p>
            </div>
          </div>
          
          {/* Network Installation Card */}
          <div className="networking-card">
            <div className="networking-card-bar" style={{ backgroundColor: secondaryColor }}></div>
            <div className="networking-card-content">
              <div className="icon-container" style={{ backgroundColor: "#dbeafe" }}>
                <Link size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="networking-title">Precision Installation</h2>
              <p className="networking-description">
                Meticulous deployment of premium networking infrastructure including advanced routers, 
                switches, and sophisticated cabling systems for enterprise-grade reliability.
              </p>
            </div>
          </div>
          
          {/* Network Security Card */}
          <div className="networking-card">
            <div className="networking-card-bar" 
              style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
            </div>
            <div className="networking-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Shield size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="networking-title">Fortified Network Security</h2>
              <p className="networking-description">
                Comprehensive protection solutions implementing multi-layered security protocols 
                to safeguard your critical data and ensure uncompromised network integrity.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="why-choose-container">
          <div className="gradient-bar" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
          </div>
          <div className="why-choose-content">
            <h2 className="why-choose-title">
              Why Choose Our <span className="font-medium">Premium</span> Networking Solutions
            </h2>
            
            <div className="reasons-grid">
              {/* Reason items */}
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Wifi size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Uninterrupted Connectivity</h3>
                  <p className="reason-description">
                    Enterprise-grade network infrastructure ensuring continuous, high-performance 
                    connectivity with built-in redundancy for mission-critical operations.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Check size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Bespoke Architecture</h3>
                  <p className="reason-description">
                    Custom-engineered network solutions meticulously designed to address 
                    your specific operational requirements and adapt to evolving business needs.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Network size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Elite Implementation</h3>
                  <p className="reason-description">
                    Precision installation and configuration by certified network specialists ensuring 
                    optimal performance, minimal downtime, and seamless integration.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Shield size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Advanced Security Protocols</h3>
                  <p className="reason-description">
                    State-of-the-art security measures implementing industry-leading technologies to 
                    protect against sophisticated cyber threats and unauthorized network access.
                  </p>
                </div>
              </div>
              
              <div className="special-reason-item">
                <div className="special-reason-icon-container"
                  style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
                  <Check size={20} className="text-white" />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Dedicated Monitoring & Support</h3>
                  <p className="reason-description">
                    Comprehensive ongoing support with proactive network monitoring, rapid issue resolution, 
                    and regular performance optimization to ensure your networking infrastructure consistently 
                    exceeds operational expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-container">
          <div className="cta-background" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}></div>
          <div className="cta-content">
            <h2 className="cta-title">Ready to Transform Your Network Infrastructure?</h2>
            <p className="cta-description">
              Connect with our specialists to develop a networking solution that elevates your business capabilities.
            </p>
            <button className="cta-button">
              <a href="/contactUs">Schedule Network Consultation</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LuxuryNetworking;