import React, { useEffect, useRef } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faGlobe, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

// Import logo - Update the path as needed
import logo from '../images/logo.png';

function Footer() {
    const currentYear = new Date().getFullYear();
    const footerRef = useRef(null);
    
    // Add mouse move effect for premium glass effect
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!footerRef.current) return;
            
            const sections = footerRef.current.querySelectorAll('.myfooter-section');
            
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                
                section.style.setProperty('--mouse-x', `${x}px`);
                section.style.setProperty('--mouse-y', `${y}px`);
            });
        };
        
        const footer = footerRef.current;
        if (footer) {
            footer.addEventListener('mousemove', handleMouseMove);
        }
        
        return () => {
            if (footer) {
                footer.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);
    
    // Handle social media clicks
    const handleSocialClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    
    // Handle map click
    const handleMapClick = () => {
        window.open('https://www.google.com/maps?q=Kijangwani,Zanzibar', '_blank', 'noopener,noreferrer');
    };
    
    return (
        <div className='footer' ref={footerRef}>
            {/* Premium Background Elements */}
            <div className="footer-background">
                <div className="gradient-orb"></div>
                <div className="floating-particles"></div>
                <div className="glow-lines"></div>
            </div>

            <footer className="myfooter">
                <div className="myfooter-section">
                    <div className="myfooter-logo">
                        <img src={logo} alt="Company Logo" />
                        <h1>Contact and Support</h1>
                    </div>
                    <div className="myfooter-contact">
                        <div className="myfooter-contact-item">
                            <FontAwesomeIcon className='icon' icon={faPhone} />
                            <span>+255 775 666 800</span>
                        </div>
                        <div className="myfooter-contact-item">
                            <FontAwesomeIcon className='icon' icon={faEnvelope} />
                            <span>info@techmento.co.tz</span>
                        </div>
                        <div className="myfooter-contact-item">
                            <FontAwesomeIcon className='icon' icon={faGlobe} />
                            <span>
                                <a 
                                    href="https://www.techmento.co.tz" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://www.techmento.co.tz', '_blank', 'noopener,noreferrer');
                                    }}
                                    style={{ position: 'relative', zIndex: 10 }}
                                >
                                    www.techmento.co.tz
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="myfooter-section">
                    <h1>About Us</h1>
                    <p>We are dedicated to providing top-notch IT solutions that enhance your productivity and streamline your processes.
                        You can find us on our Social media. Please follow us 
                    </p>
                    <div className="myfooter-social">
                        <button 
                            onClick={() => handleSocialClick('https://www.facebook.com/techmento')}
                            className="social-button"
                            aria-label="Facebook"
                        >
                            <FontAwesomeIcon icon={faFacebookF} />
                        </button>
                        <button 
                            onClick={() => handleSocialClick('https://www.linkedin.com/company/Techmento_Solution')}
                            className="social-button"
                            aria-label="LinkedIn"
                        >
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </button>
                        <button 
                            onClick={() => handleSocialClick('https://twitter.com/techmento')}
                            className="social-button"
                            aria-label="Twitter"
                        >
                            <FontAwesomeIcon icon={faTwitter} />
                        </button>
                        <button 
                            onClick={() => handleSocialClick('https://www.instagram.com/officialtechmento')}
                            className="social-button"
                            aria-label="Instagram"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </button>
                    </div>
                </div>

                <div className="myfooter-section">
                    <h1>Visit Us</h1>
                    <div className="myfooter-address">
                        <div className="working-hours">
                            <div className="hours-header">
                                <FontAwesomeIcon icon={faClock} className="hours-icon" />
                                <span>Working Hours</span>
                            </div>
                            <p>
                                Monday to Friday: 09:00 AM - 04:00 PM<br/>
                                Saturday: 09:00 AM - 01:00 PM
                            </p>
                        </div>
                        <div className="location">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
                            <span>Kijangwani, Zanzibar</span>
                        </div>
                    </div>
                    <button 
                        onClick={handleMapClick}
                        className="myfooter-map-link"
                        style={{ cursor: 'pointer', position: 'relative', zIndex: 10 }}
                    >
                        <span>View on Google Maps</span>
                        <div className="map-link-glow"></div>
                    </button>
                </div>
            </footer>

            <div className="myfooter-copyright">
                <div className="copyright-content">
                    <div className="copyright-line"></div>
                    <p>
                        <span className="copyright-symbol">©</span>
                        <span className="copyright-year">{currentYear}</span>
                        <span className="copyright-text">Techmento. All Rights Reserved.</span>
                    </p>
                    <p className="developer-credit">
                        <span className="credit-text">Developed and Maintained By</span>
                        <span className="company-name">Techmento</span>
                    </p>
                    <div className="copyright-line"></div>
                </div>
            </div>
        </div>
    );
}

export default Footer;