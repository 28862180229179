import React, { useEffect, useRef, useState } from "react";
import './Reason.css';

const reasons = [
    {
        icon: "💎",
        title: "Elite Expertise",
        subtitle: "Professional Excellence",
        description: "Our team of industry veterans brings unparalleled expertise to every project.",
        gradient: "blue"
    },
    {
        icon: "🚀",
        title: "Innovation Masters",
        subtitle: "Future Technology",
        description: "Pioneering next-generation solutions that transform business landscapes.",
        gradient: "purple"
    },
    {
        icon: "🎯",
        title: "Strategic Excellence",
        subtitle: "Perfect Execution",
        description: "Delivering exceptional results that consistently exceed expectations.",
        gradient: "gold"
    },
    {
        icon: "⚡",
        title: "Rapid Development",
        subtitle: "Swift Solutions",
        description: "Fast-paced development with maintained quality and precision.",
        gradient: "green"
    },
    {
        icon: "🛡️",
        title: "Reliable Security",
        subtitle: "Protected Future",
        description: "Enterprise-grade security ensuring your business stays protected.",
        gradient: "red"
    },
    {
        icon: "🔄",
        title: "Seamless Integration",
        subtitle: "Unified Systems",
        description: "Effortlessly connect and synchronize with your existing technology ecosystem.",
        gradient: "teal"
    }
];

function Reason() {
    const containerRef = useRef(null);
    const showcaseRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // Create particles for the background
    useEffect(() => {
        if (!containerRef.current) return;
        
        const particleLayer = document.createElement('div');
        particleLayer.className = 'particle-layer';
        
        // Create particles
        for (let i = 0; i < 50; i++) {
            const particle = document.createElement('div');
            particle.className = 'particle';
            
            // Random position
            particle.style.left = `${Math.random() * 100}%`;
            particle.style.top = `${Math.random() * 100}%`;
            
            // Random size
            const size = 1 + Math.random() * 2;
            particle.style.width = `${size}px`;
            particle.style.height = `${size}px`;
            
            // Random opacity
            particle.style.opacity = 0.1 + Math.random() * 0.3;
            
            // Random animation duration and delay
            particle.style.animationDuration = `${15 + Math.random() * 15}s`;
            particle.style.animationDelay = `${Math.random() * 10}s`;
            
            particleLayer.appendChild(particle);
        }

        // Make sure the reason-bg exists before appending
        const bgElement = containerRef.current.querySelector('.reason-bg');
        if (bgElement) {
            bgElement.appendChild(particleLayer);
        }
        
        setIsLoaded(true);
        
        return () => {
            if (particleLayer.parentNode) {
                particleLayer.parentNode.removeChild(particleLayer);
            }
        };
    }, []);

    // Ensure cards are visible on initial load, without animation dependency
    useEffect(() => {
        if (!showcaseRef.current) return;
        
        const cards = showcaseRef.current.querySelectorAll('.reason-card');
        
        // Make cards visible immediately with a slight delay between each
        cards.forEach((card, index) => {
            setTimeout(() => {
                card.style.opacity = '1';
                card.style.transform = 'translateY(0)';
            }, index * 100);
        });
    }, []);

    // Create floating orbs for each card
    const createOrbs = (count) => {
        return Array.from({ length: count }).map((_, i) => {
            const size = 5 + Math.random() * 10;
            const top = Math.random() * 100;
            const left = Math.random() * 100;
            const animationDuration = 3 + Math.random() * 7;
            const animationDelay = Math.random() * 5;
            
            return (
                <div
                    key={i}
                    className="orb"
                    style={{
                        top: `${top}%`,
                        left: `${left}%`,
                        width: `${size}px`,
                        height: `${size}px`,
                        animationDuration: `${animationDuration}s`,
                        animationDelay: `${animationDelay}s`
                    }}
                />
            );
        });
    };

    // Mouse interaction effect
    useEffect(() => {
        if (!isLoaded || !showcaseRef.current) return;
        
        const cards = showcaseRef.current.querySelectorAll('.reason-card');
        if (!cards || cards.length === 0) return;
        
        const handleMouseMove = (e) => {
            const card = e.currentTarget;
            const rect = card.getBoundingClientRect();
            
            // Calculate mouse position relative to card
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            
            // Calculate rotation based on mouse position
            const centerX = rect.width / 2;
            const centerY = rect.height / 2;
            const rotateX = (y - centerY) / 20;
            const rotateY = (centerX - x) / 20;
            
            // Apply rotation and other effects
            card.style.transform = `translateY(-10px) perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
            
            // Update the glow position
            const iconGlow = card.querySelector('.icon-glow');
            if (iconGlow) {
                iconGlow.style.background = `radial-gradient(circle at ${x}px ${y}px, var(--accent-color-light), transparent 70%)`;
            }
        };
        
        const handleMouseLeave = (e) => {
            const card = e.currentTarget;
            card.style.transform = '';
        };
        
        const handleMouseEnter = (e) => {
            // You can add custom behavior on mouse enter if needed
        };
        
        cards.forEach(card => {
            card.addEventListener('mousemove', handleMouseMove);
            card.addEventListener('mouseleave', handleMouseLeave);
            card.addEventListener('mouseenter', handleMouseEnter);
        });
        
        return () => {
            cards.forEach(card => {
                card.removeEventListener('mousemove', handleMouseMove);
                card.removeEventListener('mouseleave', handleMouseLeave);
                card.removeEventListener('mouseenter', handleMouseEnter);
            });
        };
    }, [isLoaded]);

    // Initial styles for cards - ensuring they're visible
    const initialCardStyle = {
        transition: 'opacity 0.8s cubic-bezier(0.22, 1, 0.36, 1), transform 0.8s cubic-bezier(0.22, 1, 0.36, 1)'
    };

    return (
        <div className="reason-container" ref={containerRef}>
            {/* Premium Background Effects */}
            <div className="reason-bg">
                <div className="gradient-layer"></div>
                <div className="pattern-layer"></div>
                <div className="glow-layer"></div>
                {/* Particle layer will be added dynamically */}
            </div>

            {/* Luxury Header Section */}
            <div className="reason-header">
                <div className="header-content">
                    <span className="premium-badge">Why Choose Us</span>
                    <h1 className="main-title">
                        Elevating Business Through
                        <span className="highlight"> Innovation</span>
                    </h1>
                    <p className="subtitle">
                        Experience excellence with our premium solutions and expert team
                    </p>
                </div>
            </div>

            {/* Premium Reasons Grid */}
            <div className="reasons-showcase" ref={showcaseRef}>
                {reasons.map((reason, index) => (
                    <div 
                        key={index} 
                        className={`reason-card gradient-${reason.gradient}`}
                        style={{
                            ...initialCardStyle,
                            transitionDelay: `${index * 0.1}s`
                        }}
                    >
                        <div className="card-inner">
                            {/* Premium Icon Container */}
                            <div className="icon-container">
                                <div className="icon-bg"></div>
                                <div className="icon-wrapper">
                                    <span className="reason-icon">{reason.icon}</span>
                                </div>
                                <div className="icon-glow"></div>
                            </div>

                            {/* Luxury Content */}
                            <div className="content-wrapper">
                                <div className="title-group">
                                    <h3 className="card-title">{reason.title}</h3>
                                    <span className="card-subtitle">{reason.subtitle}</span>
                                </div>
                                <p className="card-description">{reason.description}</p>
                            </div>

                            {/* Premium Card Effects */}
                            <div className="card-effects">
                                <div className="corner-accent top-left"></div>
                                <div className="corner-accent top-right"></div>
                                <div className="corner-accent bottom-left"></div>
                                <div className="corner-accent bottom-right"></div>
                            </div>
                            
                            {/* Floating Orbs */}
                            <div className="card-orbs">
                                {createOrbs(5)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Reason;