import React from 'react';
import { Monitor, Server, Settings, Check, Cpu } from 'lucide-react';
import './Equipment.css';
import Footer from '../Footer';
import Header from '../Header';

const LuxuryEquipment = () => {
  // Your color codes
  const primaryColor = "rgba(56, 189, 248, 0.7)";
  const secondaryColor = "rgba(2, 132, 199, 0.7)";
  
  return (
    <div className="luxury-equipment-container">
      <Header />
      {/* Header Section with Gradient Background */}
      <div className="header-section">
        <div 
          className="header-gradient"
          style={{ 
            backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` 
          }}
        />
        
        <div className="header-content">
          <h1 className="header-title">
            <span className="font-normal">Premium</span> Equipment Supply
          </h1>
          <p className="header-description">
            Providing a comprehensive range of high-performance computer hardware and software solutions 
            precisely tailored to elevate your business operations and ensure uncompromised reliability.
          </p>
        </div>
      </div>
      
      {/* Equipment Section */}
      <div className="equipment-section">
        <div className="equipment-grid">
          {/* Computer Hardware Card */}
          <div className="equipment-card">
            <div className="equipment-card-bar" style={{ backgroundColor: primaryColor }}></div>
            <div className="equipment-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Monitor size={30} style={{ color: primaryColor }} />
              </div>
              <h2 className="equipment-title">Computer Hardware</h2>
              <p className="equipment-description">
                We supply elite desktop computers, laptops, and peripherals, ensuring your business 
                operations function with exceptional reliability and efficiency.
              </p>
            </div>
          </div>
          
          {/* Networking Equipment Card */}
          <div className="equipment-card">
            <div className="equipment-card-bar" style={{ backgroundColor: secondaryColor }}></div>
            <div className="equipment-card-content">
              <div className="icon-container" style={{ backgroundColor: "#dbeafe" }}>
                <Server size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="equipment-title">Networking Equipment</h2>
              <p className="equipment-description">
                Our premium selection includes advanced switches, routers, and networking infrastructure 
                to establish and maintain secure, high-performance networks.
              </p>
            </div>
          </div>
          
          {/* Software Solutions Card */}
          <div className="equipment-card">
            <div className="equipment-card-bar" 
              style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
            </div>
            <div className="equipment-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Settings size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="equipment-title">Software Solutions</h2>
              <p className="equipment-description">
                We offer a sophisticated collection of software solutions, from operating systems to 
                specialized tools that align perfectly with your unique business requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="why-choose-container">
          <div className="gradient-bar" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
          </div>
          <div className="why-choose-content">
            <h2 className="why-choose-title">
              Why Choose Our <span className="font-medium">Premium</span> Equipment Supply
            </h2>
            
            <div className="reasons-grid">
              {/* Reason items */}
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Check size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Superior Quality Products</h3>
                  <p className="reason-description">
                    Exceptional hardware and software solutions sourced exclusively from industry-leading brands, 
                    ensuring unparalleled performance and longevity.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Cpu size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Expert Consultation</h3>
                  <p className="reason-description">
                    Personalized recommendations from our technical specialists based on a comprehensive 
                    assessment of your specific business requirements.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Check size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Comprehensive Support</h3>
                  <p className="reason-description">
                    Full-spectrum support encompassing initial consultation through implementation, 
                    including installation, configuration, and ongoing maintenance.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Check size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Value-Optimized Pricing</h3>
                  <p className="reason-description">
                    Strategic pricing structure that maximizes return on investment without 
                    compromising on quality, performance, or service excellence.
                  </p>
                </div>
              </div>
              
              <div className="special-reason-item">
                <div className="special-reason-icon-container"
                  style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
                  <Check size={20} className="text-white" />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Expedited Procurement & Delivery</h3>
                  <p className="reason-description">
                    Streamlined procurement process and prioritized delivery schedules that align with your 
                    operational timelines, ensuring minimal disruption to your business continuity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-container">
          <div className="cta-background" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}></div>
          <div className="cta-content">
            <h2 className="cta-title">Ready to Upgrade Your Technology Infrastructure?</h2>
            <p className="cta-description">
              Partner with us to access premium equipment solutions that provide the foundation for your business success.
            </p>
            <button className="cta-button">
              <a href="/contactUs">Request Equipment Consultation</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LuxuryEquipment;