import React, { useEffect, useState, useRef } from 'react';
import { 
  FaNetworkWired, FaCode, FaCloud, FaHeadset, 
  FaChartLine, FaShieldAlt, FaArrowRight 
} from 'react-icons/fa';
import './Service.css';

const services = [
  {
    title: 'Enterprise Network Solutions',
    description: 'Sophisticated network infrastructure that scales with your business and ensures optimal performance.',
    icon: <FaNetworkWired />,
    animation: 'slide-left',
    accent: '#38bdf8'
  },
  {
    title: 'Custom Software Development',
    description: 'Tailored software solutions that transform your business processes and elevate your digital presence.',
    icon: <FaCode />,
    animation: 'slide-right',
    accent: '#818cf8'
  },
  {
    title: 'Cloud Infrastructure',
    description: 'Secure, scalable cloud solutions designed for enterprise-level performance and reliability.',
    icon: <FaCloud />,
    animation: 'slide-top',
    accent: '#0284c7'
  },
  {
    title: 'Premium IT Support',
    description: 'Dedicated IT support with rapid response times and expert solutions for all technical challenges.',
    icon: <FaHeadset />,
    animation: 'slide-bottom',
    accent: '#38bdf8'
  },
  {
    title: 'Digital Transformation',
    description: 'Strategic digital evolution pathways that position your business for sustained future success.',
    icon: <FaChartLine />,
    animation: 'slide-left',
    accent: '#818cf8'
  },
  {
    title: 'Enterprise Security',
    description: 'Advanced security systems that protect your valuable digital assets and sensitive information.',
    icon: <FaShieldAlt />,
    animation: 'slide-right',
    accent: '#0284c7'
  },
];

function Service() {
  const [isVisible, setIsVisible] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const serviceSection = document.querySelector('.service-section');
    if (serviceSection) observer.observe(serviceSection);

    return () => observer.disconnect();
  }, []);

  // Track mouse position for card hover effects
  const handleMouseMove = (e, cardElement) => {
    if (!cardElement) return;
    
    const rect = cardElement.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    // Calculate percentage within the card (0-100)
    const xPercent = Math.round((x / rect.width) * 100);
    const yPercent = Math.round((y / rect.height) * 100);
    
    // Calculate rotation based on mouse position
    // Mouse on left side -> negative rotation, right side -> positive rotation
    const rotateY = ((xPercent - 50) / 50) * 3; // Max 3 degree rotation
    const rotateX = ((50 - yPercent) / 50) * 2; // Max 2 degree rotation
    
    cardElement.style.setProperty('--mouse-x', `${x}px`);
    cardElement.style.setProperty('--mouse-y', `${y}px`);
    cardElement.style.setProperty('--rotate-x', `${rotateX}deg`);
    cardElement.style.setProperty('--rotate-y', `${rotateY}deg`);
  };

  // Update global mouse position for subtle background effects
  const handleContainerMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;
      setMousePosition({ x, y });
    }
  };

  return (
    <div 
      className='service-section' 
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
      style={{
        '--mouse-bg-x': `${mousePosition.x}%`,
        '--mouse-bg-y': `${mousePosition.y}%`
      }}
    >
      <div className='luxury-background'></div>
      <div className='floating-particles'></div>
      <div className='light-beams'>
        <div className='light-beam'></div>
        <div className='light-beam'></div>
        <div className='light-beam'></div>
      </div>
      
      <div className='service-header-container'>
        <div className='ultra-luxury-badge'>
          <span className='badge-glow'></span>
          <span className='badge-text'>Premium Solutions</span>
        </div>
        
        <h2 className='service-intro'>
          <span className='premium-text'>Elevate Your Business Potential</span>{' '}
          
        </h2>
        
        <p className='service-subintro'>
          Transforming enterprises through cutting-edge technology and exceptional service delivery
        </p>
      </div>

      <div className='service-container'>
        {services.map((service, index) => (
          <div
            key={index}
            className={`services-card ${isVisible ? `animation-active ${service.animation}` : ''}`}
            style={{ 
              animationDelay: `${index * 0.15}s`,
              '--card-accent': service.accent
            }}
            onMouseEnter={(e) => {
              // Set custom properties for hover effect
              const card = e.currentTarget;
              card.style.setProperty('--card-gradient', `linear-gradient(135deg, ${service.accent}, #0284c7)`);
            }}
            onMouseLeave={() => {}}
            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
          >
            <div className='card-content'>
              <div className='corner-top-left card-corner'></div>
              <div className='corner-top-right card-corner'></div>
              <div className='corner-bottom-left card-corner'></div>
              <div className='corner-bottom-right card-corner'></div>
              
           
              
              <div className='service-icon-container'>
                <div className='icon-background'></div>
                <div className='service-icon' style={{ color: service.accent }}>{service.icon}</div>
              </div>
              
              <div className='service-text'>
                <h3 className='service-title'>{service.title}</h3>
                <p className='service-description'>{service.description}</p>
              </div>

              <button className='premium-button'>
                <span className='button-text'>Explore</span>
                <FaArrowRight className='button-icon' />
              </button>
              
              <div className='card-shine-effect'></div>
              <div className='card-ambient-light'></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Service;