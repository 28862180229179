import React, { useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import './AboutUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faStar, 
    faHandshake, 
    faUsers, 
    faTrophy, 
    faLightbulb,
    faCode,
    faNetworkWired,
    faHeadset,
    faChalkboardTeacher,
    faShieldAlt
} from '@fortawesome/free-solid-svg-icons';

function AboutUs() {
    const containerRef = useRef(null);
    
    // Mouse move effect for premium glass effect
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!containerRef.current) return;
            
            const sections = containerRef.current.querySelectorAll('.company-section');
            
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                
                section.style.setProperty('--mouse-x', `${x}px`);
                section.style.setProperty('--mouse-y', `${y}px`);
            });
        };
        
        const container = containerRef.current;
        if (container) {
            container.addEventListener('mousemove', handleMouseMove);
        }
        
        return () => {
            if (container) {
                container.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);
    
    // Make sections visible immediately and then add animation
    useEffect(() => {
        // Make sure this runs after the component is mounted and sections are in the DOM
        setTimeout(() => {
            const sections = document.querySelectorAll('.company-section');
            if (!sections || sections.length === 0) return;

            sections.forEach((section, index) => {
                // Set a delay for each section to create a staggered animation
                setTimeout(() => {
                    section.style.opacity = '1';
                    section.style.transform = 'translateY(0)';
                }, index * 150);
            });
        }, 100);
    }, []);

    return (
        <div>
            <Header />
            <div className="about-us-container" ref={containerRef}>
                {/* Premium Background Elements */}
                <div className="about-us-background">
                    <div className="about-gradient-orb"></div>
                    <div className="about-pattern-layer"></div>
                    <div className="about-glow-lines"></div>
                </div>
                
                <div className="about-us-content-wrapper">
                    <div className="about-us-header">
                        <h1>Welcome to Techmento</h1>
                        <p>Your trusted partner in IT solutions</p>
                        <p className="slogan">Redefining IT Possibilities</p>
                    </div>

                    <div className="about-us-content">
                        <div className="company-section company-description" style={{transitionDelay: "0.1s"}}>
                            <h2>About Us</h2>
                            <p>Techmento is a leading IT solutions provider dedicated to delivering innovative and tailored technology services. With a team of experienced professionals, we offer cutting-edge solutions that drive business success and efficiency. Our expertise spans across software development, IT support, and network solutions, all designed to meet the evolving needs of our clients.</p>
                        </div>

                        <div className="company-grid">
                            <div className="company-section company-vision" style={{transitionDelay: "0.2s"}}>
                                <h2>Our Vision</h2>
                                <p>At Techmento, our vision is to be the premier IT partner that empowers businesses to harness the full potential of technology. We aim to revolutionize the way companies operate by providing transformative IT solutions that enhance productivity, drive innovation, and achieve long-term success. Our commitment is to deliver excellence and make a meaningful impact in the tech industry.</p>
                            </div>

                            <div className="company-section company-mission" style={{transitionDelay: "0.3s"}}>
                                <h2>Our Mission</h2>
                                <p>Our mission is to deliver exceptional IT solutions that address our clients' unique challenges and opportunities. We strive to offer unparalleled service through a customer-centric approach, ensuring that our solutions are not only effective but also align with our clients' strategic goals. Our goal is to be a trusted advisor and partner, committed to helping our clients achieve their business objectives through technology.</p>
                            </div>
                        </div>

                        <div className="company-section company-values" style={{transitionDelay: "0.4s"}}>
                            <h2>Core Values</h2>
                            <div className="company-values-list">
                                <div className="value-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faLightbulb} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Innovation</div>
                                        <div className="item-description">Continuously advancing and evolving to provide the latest and most effective technology solutions.</div>
                                    </div>
                                </div>
                                
                                <div className="value-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faStar} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Integrity</div>
                                        <div className="item-description">Upholding the highest standards of honesty and transparency in all our interactions.</div>
                                    </div>
                                </div>
                                
                                <div className="value-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faHandshake} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Customer Focus</div>
                                        <div className="item-description">Prioritizing the needs and success of our clients to deliver exceptional results.</div>
                                    </div>
                                </div>
                                
                                <div className="value-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faTrophy} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Excellence</div>
                                        <div className="item-description">Committing to the highest levels of quality and professionalism in every project.</div>
                                    </div>
                                </div>
                                
                                <div className="value-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faUsers} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Collaboration</div>
                                        <div className="item-description">Fostering a collaborative environment to drive creativity and success.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="company-section company-services" style={{transitionDelay: "0.5s"}}>
                            <h2>Our Services</h2>
                            <div className="company-services-list">
                                <div className="service-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faCode} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Custom Software Development</div>
                                        <div className="item-description">Tailored software solutions designed to meet your unique business requirements.</div>
                                    </div>
                                </div>
                                
                                <div className="service-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faNetworkWired} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Equipment Supply and Networking</div>
                                        <div className="item-description">Comprehensive hardware solutions and expert network infrastructure setup.</div>
                                    </div>
                                </div>
                                
                                <div className="service-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faHeadset} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">IT Support and Maintenance</div>
                                        <div className="item-description">Reliable technical support to ensure smooth operation of your IT systems.</div>
                                    </div>
                                </div>
                                
                                <div className="service-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faChalkboardTeacher} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">IT Consultancy</div>
                                        <div className="item-description">Strategic IT guidance to help you navigate the complex technology landscape.</div>
                                    </div>
                                </div>
                                
                                <div className="service-item">
                                    <div className="item-icon">
                                        <FontAwesomeIcon icon={faShieldAlt} />
                                    </div>
                                    <div className="item-content">
                                        <div className="item-title">Cyber Security Solutions</div>
                                        <div className="item-description">Advanced security systems to protect your valuable data and digital assets.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;