import React from 'react';
import { Camera, Smartphone, Code, Check, Monitor, Link, Shield } from 'lucide-react';
import './SoftwareDevelopment.css';
import Footer from '../Footer';
import Header from '../Header';

const SoftwareDevelopment = () => {
  // Your color codes
  const primaryColor = "rgba(56, 189, 248, 0.7)";
  const secondaryColor = "rgba(2, 132, 199, 0.7)";
  
  return (
    <div className="luxury-software-container">
      <Header />
 
      <div className="header-section">
        <div 
          className="header-gradient"
          style={{ 
            backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` 
          }}
        />
        
        <div className="header-content">
          <h1 className="header-title">
            <span className="font-normal">Innovative</span> Software Development
          </h1>
          <p className="header-description">
            We transform your ideas into exceptional digital experiences with our premium Android, iOS, and web application development services.
          </p>
        </div>
      </div>
      
      {/* Services Section */}
      <div className="services-section">
        <div className="services-grid">
          {/* Android Development Card */}
          <div className="service-card">
            <div className="service-card-bar" style={{ backgroundColor: primaryColor }}></div>
            <div className="service-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Smartphone size={30} style={{ color: primaryColor }} />
              </div>
              <h2 className="service-title">Android Development</h2>
              <p className="service-description">
                Sophisticated Android applications with intuitive interfaces, ensuring exceptional user engagement and seamless functionality.
              </p>
            </div>
          </div>
          
          {/* Web Development Card */}
          <div className="service-card">
            <div className="service-card-bar" style={{ backgroundColor: secondaryColor }}></div>
            <div className="service-card-content">
              <div className="icon-container" style={{ backgroundColor: "#dbeafe" }}>
                <Code size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="service-title">Web Application Development</h2>
              <p className="service-description">
                Elegant, responsive, and feature-rich web applications that elevate your digital presence and streamline business operations.
              </p>
            </div>
          </div>
          
          {/* iOS Development Card */}
          <div className="service-card">
            <div className="service-card-bar" 
              style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
            </div>
            <div className="service-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Camera size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="service-title">iOS Development</h2>
              <p className="service-description">
                Premium iOS applications with refined aesthetics and exceptional performance, tailored specifically for Apple devices.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="why-choose-container">
          <div className="gradient-bar" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
          </div>
          <div className="why-choose-content">
            <h2 className="why-choose-title">
              Why Choose Our <span className="font-medium">Premium</span> Development Services
            </h2>
            
            <div className="reasons-grid">
              {/* Reason items */}
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Check size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Elite Expertise</h3>
                  <p className="reason-description">
                    Our team brings decades of combined experience in crafting exceptional mobile and web applications that exceed expectations.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Monitor size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Bespoke Solutions</h3>
                  <p className="reason-description">
                    We create meticulously tailored solutions designed to address your specific business challenges and objectives.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Link size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Seamless Performance</h3>
                  <p className="reason-description">
                    Optimize user experiences with applications engineered for exceptional speed, reliability, and responsiveness.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Shield size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Premium Support</h3>
                  <p className="reason-description">
                    Enjoy dedicated, attentive support throughout your project lifecycle and beyond deployment.
                  </p>
                </div>
              </div>
              
              <div className="special-reason-item">
                <div className="special-reason-icon-container"
                  style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
                  <Check size={20} className="text-white" />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Refined Agile Methodology</h3>
                  <p className="reason-description">
                    Benefit from our streamlined development approach that delivers superior results with efficiency and precision, bringing your product to market with exceptional speed without compromising quality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-container">
          <div className="cta-background" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}></div>
          <div className="cta-content">
            <h2 className="cta-title">Ready to Elevate Your Digital Experience?</h2>
            <p className="cta-description">
              Partner with us to transform your vision into an exceptional digital reality.
            </p>
            <button className="cta-button">
              <a href="/contactUs">Schedule a Consultation</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SoftwareDevelopment;