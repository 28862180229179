import React from 'react';
import { Settings, Handshake, MessageCircle, Check, Award, Users } from 'lucide-react';
import './Consultant.css';
import Footer from '../Footer';
import Header from '../Header';

const LuxuryConsultant = () => {
  // Your color codes
  const primaryColor = "rgba(56, 189, 248, 0.7)";
  const secondaryColor = "rgba(2, 132, 199, 0.7)";
  
  return (
    <div className="luxury-consultant-container">
      <Header />
      {/* Header Section with Gradient Background */}
      <div className="header-section">
        <div 
          className="header-gradient"
          style={{ 
            backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` 
          }}
        />
        
        <div className="header-content">
          <h1 className="header-title">
            <span className="font-normal">Strategic</span> IT Consultancy
          </h1>
          <p className="header-description">
            Providing expert guidance and innovative technology solutions that transform business 
            challenges into strategic advantages and sustainable growth opportunities.
          </p>
        </div>
      </div>
      
      {/* Consultancy Services Section */}
      <div className="consultant-section">
        <div className="consultant-grid">
          {/* Technology Implementation Card */}
          <div className="consultant-card">
            <div className="consultant-card-bar" style={{ backgroundColor: primaryColor }}></div>
            <div className="consultant-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Settings size={30} style={{ color: primaryColor }} />
              </div>
              <h2 className="consultant-title">Technology Implementation</h2>
              <p className="consultant-description">
                Strategic deployment of innovative technology solutions with meticulous attention 
                to integration, ensuring seamless adoption and maximum business impact.
              </p>
            </div>
          </div>
          
          {/* Collaborative Approach Card */}
          <div className="consultant-card">
            <div className="consultant-card-bar" style={{ backgroundColor: secondaryColor }}></div>
            <div className="consultant-card-content">
              <div className="icon-container" style={{ backgroundColor: "#dbeafe" }}>
                <Handshake size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="consultant-title">Collaborative Partnership</h2>
              <p className="consultant-description">
                Fostering a synergistic relationship with your team to develop customized solutions 
                that align precisely with your strategic objectives and operational realities.
              </p>
            </div>
          </div>
          
          {/* Clear Communication Card */}
          <div className="consultant-card">
            <div className="consultant-card-bar" 
              style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
            </div>
            <div className="consultant-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <MessageCircle size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="consultant-title">Transparent Communication</h2>
              <p className="consultant-description">
                Maintaining exceptional clarity and openness throughout the consultancy process, 
                ensuring all stakeholders are aligned and informed at every critical milestone.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="why-choose-container">
          <div className="gradient-bar" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
          </div>
          <div className="why-choose-content">
            <h2 className="why-choose-title">
              Why Choose Our <span className="font-medium">Premium</span> Consultancy Services
            </h2>
            
            <div className="reasons-grid">
              {/* Reason items */}
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Users size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Distinguished Expertise</h3>
                  <p className="reason-description">
                    Access to an elite team of industry specialists with extensive experience 
                    delivering transformative technology solutions across diverse sectors.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Check size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Bespoke Solutions</h3>
                  <p className="reason-description">
                    Meticulously crafted strategies and implementations designed specifically 
                    to address your unique challenges and amplify your competitive advantages.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Award size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Proven Excellence</h3>
                  <p className="reason-description">
                    An established record of delivering exceptional outcomes and measurable 
                    value through strategic technology deployment and process optimization.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Settings size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Comprehensive Methodology</h3>
                  <p className="reason-description">
                    A holistic approach that addresses all dimensions of technology integration, 
                    from strategic planning through implementation and continuous optimization.
                  </p>
                </div>
              </div>
              
              <div className="special-reason-item">
                <div className="special-reason-icon-container"
                  style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
                  <Check size={20} className="text-white" />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Dedicated Partnership & Support</h3>
                  <p className="reason-description">
                    A commitment to your long-term success through ongoing strategic guidance, 
                    implementation support, and proactive optimization recommendations that ensure 
                    maximum return on your technology investments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-container">
          <div className="cta-background" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}></div>
          <div className="cta-content">
            <h2 className="cta-title">Ready to Transform Your Technology Strategy?</h2>
            <p className="cta-description">
              Schedule a complimentary consultation to explore how our expertise can elevate your business.
            </p>
            <button className="cta-button">
              <a href="/contactUs">Request Strategic Assessment</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LuxuryConsultant;