import React from 'react';
import { Headphones, Network, Settings, Check, Clock } from 'lucide-react';
import './Support.css';
import Footer from '../Footer';
import Header from '../Header';

const LuxurySupport = () => {
  // Your color codes
  const primaryColor = "rgba(56, 189, 248, 0.7)";
  const secondaryColor = "rgba(2, 132, 199, 0.7)";
  
  return (
    <div className="luxury-support-container">
      <Header />
      {/* Header Section with Gradient Background */}
      <div className="header-section">
        <div 
          className="header-gradient"
          style={{ 
            backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` 
          }}
        />
        
        <div className="header-content">
          <h1 className="header-title">
            <span className="font-normal">Premium</span> IT Support & Maintenance
          </h1>
          <p className="header-description">
            Delivering exceptional IT support and proactive maintenance services to ensure your 
            technology infrastructure operates at peak performance with minimal disruption.
          </p>
        </div>
      </div>
      
      {/* Support Services Section */}
      <div className="support-section">
        <div className="support-grid">
          {/* 24/7 Support Card */}
          <div className="support-card">
            <div className="support-card-bar" style={{ backgroundColor: primaryColor }}></div>
            <div className="support-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Headphones size={30} style={{ color: primaryColor }} />
              </div>
              <h2 className="support-title">24/7 Elite Technical Support</h2>
              <p className="support-description">
                Our dedicated team of technical specialists is available around the clock, 
                providing immediate assistance and ensuring continuous operational excellence.
              </p>
            </div>
          </div>
          
          {/* Network Troubleshooting Card */}
          <div className="support-card">
            <div className="support-card-bar" style={{ backgroundColor: secondaryColor }}></div>
            <div className="support-card-content">
              <div className="icon-container" style={{ backgroundColor: "#dbeafe" }}>
                <Network size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="support-title">Advanced Network Diagnostics</h2>
              <p className="support-description">
                Sophisticated network analysis and troubleshooting services that rapidly 
                identify and resolve connectivity issues, ensuring optimal network performance.
              </p>
            </div>
          </div>
          
          {/* System Maintenance Card */}
          <div className="support-card">
            <div className="support-card-bar" 
              style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
            </div>
            <div className="support-card-content">
              <div className="icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                <Settings size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="support-title">Proactive System Maintenance</h2>
              <p className="support-description">
                Comprehensive system optimization and preventative maintenance protocols that 
                safeguard your infrastructure and maximize technology investments.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="why-choose-container">
          <div className="gradient-bar" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
          </div>
          <div className="why-choose-content">
            <h2 className="why-choose-title">
              Why Choose Our <span className="font-medium">Premium</span> IT Support Services
            </h2>
            
            <div className="reasons-grid">
              {/* Reason items */}
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Clock size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Continuous Availability</h3>
                  <p className="reason-description">
                    Access to highly-trained IT specialists 24 hours a day, ensuring immediate 
                    response and resolution regardless of when issues arise.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Check size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Expedited Resolution</h3>
                  <p className="reason-description">
                    Accelerated response protocols with prioritized issue management to 
                    minimize disruption and maintain operational continuity.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Network size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Comprehensive Coverage</h3>
                  <p className="reason-description">
                    End-to-end support across all IT domains including hardware, software, 
                    networking, security, and cloud infrastructure.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Settings size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Strategic Maintenance</h3>
                  <p className="reason-description">
                    Forward-thinking system monitoring and maintenance that preemptively 
                    addresses potential issues before they impact performance.
                  </p>
                </div>
              </div>
              
              <div className="special-reason-item">
                <div className="special-reason-icon-container"
                  style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
                  <Check size={20} className="text-white" />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Elite Technical Expertise</h3>
                  <p className="reason-description">
                    A team of extensively certified IT professionals with specialized knowledge across diverse 
                    technological domains, equipped to handle complex challenges with precision and expertise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-container">
          <div className="cta-background" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}></div>
          <div className="cta-content">
            <h2 className="cta-title">Experience Unparalleled IT Support</h2>
            <p className="cta-description">
              Partner with us to ensure your technology infrastructure receives the exceptional support it deserves.
            </p>
            <button className="cta-button">
              <a href="/contactUs">Request Support Consultation</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LuxurySupport;