import './Key.css';
import whatsapp from '../images/whatsapp.png';
import React, { useEffect, useState } from 'react';
import { FaTachometerAlt, FaDollarSign, FaBusinessTime } from 'react-icons/fa';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

function Key() {
  const [news, setNews] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Fetch news from your backend
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('your-backend-url/api/news');
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };
    fetchNews();
  }, []);

  useEffect(() => {
    const solutions = document.querySelectorAll('.solution');

    const options = {
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, options);

    solutions.forEach(solution => {
      observer.observe(solution);
    });

    return () => {
      solutions.forEach(solution => {
        observer.unobserve(solution);
      });
    };
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % news.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? news.length - 1 : prev - 1));
  };

  return (
    <div className='key-container'>
      <div className='key-wrapper'>
        <div className='news-section'>
          <div className='section-header'>
            <h2 className='news-title'>Latest Updates</h2>
            <div className='slider-controls'>
              <button className='slider-button prev' onClick={prevSlide}>
                <BsChevronLeft />
              </button>
              <button className='slider-button next' onClick={nextSlide}>
                <BsChevronRight />
              </button>
            </div>
          </div>
          <div className='news-slider'>
            <div className='news-slides'>
              {news.map((item, index) => (
                <div 
                  key={index}
                  className={`news-slide ${index === currentSlide ? 'active' : ''}`}
                >
                  <img src={item.image} alt={item.title} />
                  <div className='news-content'>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <span className='news-date'>{new Date(item.date).toLocaleDateString()}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='solutions-section'>
          <div className='luxury-badge'>
            <span>Premium</span>
            <div className='badge-glow'></div>
          </div>
          
          <div className='solutions-header'>
            <div className='header-decoration'>
              <div className='luxury-line-left'></div>
              <h1>
                <span className='gradient-accent'>Techmento</span> Solution
                <div className='header-subtitle'>Resolves 3 Key Problems For Business</div>
              </h1>
              <div className='luxury-line-right'></div>
            </div>
            <div className='header-content'>
              <p>We've always known that for businesses large and small, 
                IT can be a real challenge to manage. Staying on top of
                your technology includes controlling the costs associated
                with keeping in-house staff up-to-date with training, 
                certifications, and current trends.</p>
            </div>
          </div>

          <div className='solutions-grid'>
            <div className='solution'>
              <div className='solution-glow'></div>
              <div className='solution-inner'>
                <div className='solution-icon-wrapper'>
                  <div className='icon-orbit'></div>
                  <FaTachometerAlt className='solution-icon' />
                </div>
                <div className='solution-content'>
                  <h2>Poor IT Performance</h2>
                  <p>Poorly performing IT Partners and IT Services reduce
                    productivity and risk the integrity of your business.</p>
                  <div className='luxury-details'>
                    <span className='detail-dot'></span>
                    <span className='detail-line'></span>
                  </div>
                </div>
              </div>
            </div>

            <div className='solution'>
              <div className='solution-glow'></div>
              <div className='solution-inner'>
                <div className='solution-icon-wrapper'>
                  <div className='icon-orbit'></div>
                  <FaDollarSign className='solution-icon' />
                </div>
                <div className='solution-content'>
                  <h2>Controlling IT Costs</h2>
                  <p>IT Services are one of the largest sunken or ongoing
                    costs in any business, therefore, important to manage.</p>
                  <div className='luxury-details'>
                    <span className='detail-dot'></span>
                    <span className='detail-line'></span>
                  </div>
                </div>
              </div>
            </div>

            <div className='solution'>
              <div className='solution-glow'></div>
              <div className='solution-inner'>
                <div className='solution-icon-wrapper'>
                  <div className='icon-orbit'></div>
                  <FaBusinessTime className='solution-icon' />
                </div>
                <div className='solution-content'>
                  <h2>Unexpected Technical Issues</h2>
                  <p>IT issues can occur anytime, hindering productivity and
                    causing business disruptions.</p>
                  <div className='luxury-details'>
                    <span className='detail-dot'></span>
                    <span className='detail-line'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default Key;
