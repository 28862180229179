import React from 'react';
import { Shield, Globe, Search, Check, Lock, AlertTriangle } from 'lucide-react';
import './CyberSecurity.css';
import Footer from '../Footer';
import Header from '../Header';

const LuxuryCyberSecurity = () => {
  // Your color codes
  const primaryColor = "rgba(56, 189, 248, 0.7)";
  const secondaryColor = "rgba(2, 132, 199, 0.7)";
  
  return (
    <div className="luxury-cybersecurity-container">
    <Header />
    {/* Header Section with Gradient Background */}
      <div className="header-section">
        <div 
          className="header-gradient"
          style={{ 
            backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` 
          }}
        />
        
        <div className="header-content">
          <h1 className="header-title">
            <span className="font-normal">Advanced</span> Cyber Security
          </h1>
          <p className="header-description">
            Safeguard your digital assets with sophisticated security solutions engineered to 
            protect your enterprise from evolving threats in today's complex cyber landscape.
          </p>
        </div>
      </div>
      
      {/* Cybersecurity Services Section */}
      <div className="cybersecurity-section">
        <div className="cybersecurity-grid">
          {/* Threat Protection Card */}
          <div className="cybersecurity-card">
            <div className="cybersecurity-card-bar" style={{ backgroundColor: primaryColor }}></div>
            <div className="cybersecurity-card-content">
              <div className="icon-container" style={{ backgroundColor: "#fee2e2" }}>
                <Shield size={30} style={{ color: primaryColor }} />
              </div>
              <h2 className="cybersecurity-title">Enterprise Threat Defense</h2>
              <p className="cybersecurity-description">
                Sophisticated protection systems designed to neutralize advanced persistent threats, 
                ransomware, malware, and social engineering attacks targeting your organization.
              </p>
            </div>
          </div>
          
          {/* Network Security Card */}
          <div className="cybersecurity-card">
            <div className="cybersecurity-card-bar" style={{ backgroundColor: secondaryColor }}></div>
            <div className="cybersecurity-card-content">
              <div className="icon-container" style={{ backgroundColor: "#dbeafe" }}>
                <Globe size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="cybersecurity-title">Network Security Architecture</h2>
              <p className="cybersecurity-description">
                Comprehensive network protection frameworks implementing multi-layered security protocols 
                to safeguard your critical infrastructure from infiltration and exploitation.
              </p>
            </div>
          </div>
          
          {/* Vulnerability Assessment Card */}
          <div className="cybersecurity-card">
            <div className="cybersecurity-card-bar" 
              style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
            </div>
            <div className="cybersecurity-card-content">
              <div className="icon-container" style={{ backgroundColor: "#fff7ed" }}>
                <Search size={30} style={{ color: secondaryColor }} />
              </div>
              <h2 className="cybersecurity-title">Vulnerability Intelligence</h2>
              <p className="cybersecurity-description">
                Proactive security assessments that methodically identify and remediate vulnerabilities 
                in your systems, applications, and digital assets before they can be exploited.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Why Choose Us Section */}
      <div className="why-choose-section">
        <div className="why-choose-container">
          <div className="gradient-bar" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
          </div>
          <div className="why-choose-content">
            <h2 className="why-choose-title">
              Why Choose Our <span className="font-medium">Premium</span> Cyber Security Services
            </h2>
            
            <div className="reasons-grid">
              {/* Reason items */}
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Shield size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Comprehensive Protection</h3>
                  <p className="reason-description">
                    End-to-end security solutions that address all aspects of your digital ecosystem, 
                    from endpoint security to cloud infrastructure and application protection.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <AlertTriangle size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Elite Security Specialists</h3>
                  <p className="reason-description">
                    A team of certified security professionals with extensive experience in threat 
                    detection, incident response, and implementing robust security frameworks.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Search size={20} style={{ color: primaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Proactive Security Posture</h3>
                  <p className="reason-description">
                    Forward-thinking security strategies that anticipate and mitigate emerging threats 
                    before they can compromise your sensitive data and critical operations.
                  </p>
                </div>
              </div>
              
              <div className="reason-item">
                <div className="reason-icon-container" style={{ backgroundColor: "#e0f2fe" }}>
                  <Lock size={20} style={{ color: secondaryColor }} />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Tailored Security Frameworks</h3>
                  <p className="reason-description">
                    Customized security architectures designed specifically for your organization's 
                    unique risk profile, compliance requirements, and operational environment.
                  </p>
                </div>
              </div>
              
              <div className="special-reason-item">
                <div className="special-reason-icon-container"
                  style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}>
                  <Check size={20} className="text-white" />
                </div>
                <div className="reason-text-container">
                  <h3 className="reason-title">Continuous Security Intelligence</h3>
                  <p className="reason-description">
                    Advanced monitoring systems providing real-time threat intelligence and incident 
                    detection capabilities, supported by rapid response protocols that minimize potential 
                    impact and ensure business continuity throughout security events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-container">
          <div className="cta-background" 
            style={{ backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}></div>
          <div className="cta-content">
            <h2 className="cta-title">Strengthen Your Security Posture Today</h2>
            <p className="cta-description">
              Partner with our experts to develop a robust security strategy that protects your most valuable digital assets.
            </p>
            <button className="cta-button">
              <a href="/contactUs">Request Security Assessment</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LuxuryCyberSecurity; 